<template>
  <div class="page-main" v-loading="loading">
    <el-form class="edit-select" ref="merchandiseEditForm" :model="info" :rules="merchandiseRules">
      <div class="edit-select-item">
        <el-form-item prop="taxClassCode" label="税收分类编码" required>
          <el-input v-model.trim="info.taxClassCode" placeholder="请输入税收分类编码">
            <i slot="suffix" class="el-icon-more" style="color: #409eff; cursor: pointer" @click="handleChooseTaxClassCode"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="taxClassName" label="税收分类名称">
          <el-input v-model="info.taxClassName"></el-input>
        </el-form-item>
        <el-form-item prop="isTaxPreferential" label="是否享受特殊税率">
          <el-select v-model="info.isTaxPreferential" :disabled="propIsDisabled(info, 'isTaxPreferential')">
            <el-option v-for="i in isTaxPreferentialTypes" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select-item">
        <el-form-item prop="taxRateModel" label="税率（一般纳税人）" required>
          <el-select v-model="info.taxRateModel" >
            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxRateModel" label="税率（小规模纳税人）" required>
          <el-select v-model="info.ssTaxRateModel">
            <el-option v-for="i in taxRates" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="taxPreferentialContent" label="特殊税率内容">
          <el-select v-model="info.taxPreferentialContent" :disabled="propIsDisabled(info, 'taxPreferentialContent')">
            <el-option v-for="i in content" :key="i.value" :label="i.label" :value="i.value"></el-option>
          </el-select>
        </el-form-item>
      </div>
      <div class="edit-select">
        <div class="edit-select-foot">
          <el-button class="el-button-ext" @click="handleCancel">取消</el-button>
          <el-button class="el-button-ext" v-loading="saveBtnLoading" @click="handleSaveMerchandise" type="primary">保存</el-button>
        </div>
      </div>
    </el-form>
    <el-dialog append-to-body title="选择税收分类编码" :visible.sync="taxClassVisible" :show-close="false">
      <tax-class-code-mapping @handleCurrentGoods="handleCurrent"></tax-class-code-mapping>
    </el-dialog>
  </div>
</template>

<script>
import TaxClassCodeMapping from './TaxClassCodeMapping';
import {
  updateMerchandiseBatch
} from '@/service/merchandise';
import { MERCHANDISE_TAX_RATE } from '@/content/merchandiseInfo';

export default {
  name: 'MerchandiseEditBatch',
  components: { TaxClassCodeMapping },
  props: {
    ids: Array,
    content: Array,
    scene: undefined
  },
  watch: {
    'info.taxPreferentialContent'() {
      if (this.info.taxPreferentialContent != '免税' && this.info.taxPreferentialContent != '不征税') {
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
      if ((this.info.taxPreferentialContent == '免税' || this.info.taxPreferentialContent == '不征税') && this.info.taxPreferentialContent != '') {
        this.info.taxRateModel = this.info.taxPreferentialContent;
      }
    },
    'info.isTaxPreferential'() {
      if (this.info.isTaxPreferential == '0') {
        this.info.taxPreferentialContent = '';
        // 税率重置
        this.info.taxRateModel = this.info.taxRateModel != '免税' && this.info.taxRateModel != '不征税' ? this.info.taxRateModel : '';
      }
    }
  },
  computed: {
    priceDisabled() {
      var that = this.info;
      let price = this.info.price;
      if (price == null || price === '') {
        that.priceVariable = '1';
        return true;
      } else {
        return false;
      }
    }
  },
  data() {
    return {
      loading: false,
      remoteLoading: false,
      info: {},
      middleKinds: [],
      MerchandiseCategory: [{ value: '', label: '全部' }, ...this.$store.state.enums['MerchandiseCategory']],
      taxRates: MERCHANDISE_TAX_RATE,
      isTaxPreferentialTypes: [
        { value: '1', label: '享受' },
        { value: '0', label: '不享受' }
      ],
      priceVariableOptions: [
        { value: '1', label: '是' },
        { value: '0', label: '否' }
      ],
      taxSigns: [
        { value: 0, label: '是' },
        { value: 1, label: '否' }
      ],
      merchandiseRules: {
        taxClassCode: [{ required: true, message: '请输入税收分类编码!' }],
        taxRateModel: [{ required: true, message: '请选择税率!' }],
        taxPreferentialContent: [{ validator: this.checkTaxPreferentialContent, trigger: 'blur' }]
      },
      taxClassVisible: false,
      saveBtnLoading: false
    };
  },
  created() {
  },
  methods: {
    handleInput(e) {
      this.$forceUpdate();
    },
    // 校验特殊税率
    checkTaxPreferentialContent(rule, value, callback) {
      if (!value && this.info.isTaxPreferential == '1') {
        return callback(new Error('特殊税率内容不能为空!'));
      }
      callback();
    },
    // 点击取消的回调
    handleCancel(status) {
      this.$emit('handleCancelEditDialog', status);
    },
    // 重置表单
    resetForm() {
      this.$refs.merchandiseEditForm.resetFields();
    },
    // 单选商品
    handleCurrent(row) {
      this.info = JSON.parse(JSON.stringify(row));
      if (this.info.taxPreferentialContent != '') {
        this.info.isTaxPreferential = '1';
      }
      this.info.merchandiseName = ''
      this.info.remark = ''
      this.taxClassVisible = false;
    },
    // 税收分类编码 补零
    addZeroTaxClassCode(taxClassCode) {
      while (taxClassCode.length < 19) {
        taxClassCode = taxClassCode + '0';
      }
      return taxClassCode;
    },
    // 点击提交 -> 参数校验
    handleSaveMerchandise() {
      this.$refs.merchandiseEditForm.validate((valid) => {
        if (valid) this.submitMerchandise();
      });
    },
    // 选择税收分类编码
    handleChooseTaxClassCode() {
      console.log(this.info,'this.info')
      let tax = this.propIsDisabled(this.info, 'taxClassCode');
      console.log(tax,'tax')
      if (tax == true) {
        return this.taxClassVisible;
      }
      this.taxClassVisible = true;
    },
    // 提交商品信息
    async submitMerchandise() {
      // loading保存按钮阻止反复提交
      this.saveBtnLoading = true;
      // 税收分类编码后面自动补充0到19位
      let realTaxClassCode = this.info.taxClassCode;
      this.info.taxClassCode = this.addZeroTaxClassCode(realTaxClassCode);

      // 税率补正
      this.info.taxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.taxRateModel;
      this.info.ssTaxRate = this.info.taxRateModel == '免税' || this.info.taxRateModel == '不征税' ? 0 : this.info.ssTaxRateModel;
      // 新增字段给默认值
      if (this.info.isTaxPreferential == undefined || this.info.isTaxPreferential == '0') {
        this.info.isTaxPreferential = 0;
        this.info.taxPreferentialContent = '';
      }
      this.info.ids = this.ids;
      // 添加商品
      const { success } = await updateMerchandiseBatch(this.info);
      this.saveBtnLoading = false;
      if (success) {
        this.toast('批量修改商品信息成功', 'success', () => this.handleCancel('success'));
        if (this.scene == 'Billing' && this.isRetail) {
          this.$emit('edit-finish', this.info);
        }
      } else {
        this.toast('批量修改商品商品信息失败', 'error');
      }
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep .el-form-item__label {
  padding: 0;
  line-height: 24px;
}

::v-deep .el-form-item__error {
  top: 64px;
  padding-top: 2px;
}

.edit-select {
  display: flex;
  flex-direction: column;

  .edit-select-item {
    margin-bottom: 24px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .edit-select-foot {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
  }
}

.page-main {
  padding: 0 24px;

  .tax-class-code {
    height: 32px;
    width: 170px;
    display: flex;
    padding: 0 15px;
    border-radius: 4px;
    align-content: center;
    border: 1px solid #dcdfe6;
    justify-content: space-between;

    i {
      color: #3d94ff;
      line-height: 32px;
      margin-right: -4px;
    }
  }
}
</style>
